@white: #fff;
@black: #121212;
@grey: #666;
@pink: #ef91aa;
@water: #60dac6;
@violet: #b791dc;
@brown: #908169;
@blue: #84aadd;
@lightblue: #6dcdda;

@import 'font.less';

@bigblack: 'DIN-Black';
@bold: 'DIN-Bold';
@regular: 'DIN-Regular';
@medium: 'DIN-Medium';
@light: 'DIN-Light';

@curve200: .2s cubic-bezier(0.77,0,.175,1);
@curve300: .3s cubic-bezier(0.77,0,.175,1);
@curve400: .4s cubic-bezier(0.77,0,.175,1);
@curve600: .6s cubic-bezier(0.77,0,.175,1);
@curve800: .8s cubic-bezier(0.77,0,.175,1);
@curve1400: 1.4s cubic-bezier(0.77,0,.175,1);
@linear2s: 0.2s linear;
@linear3s: 0.3s linear;
@linear4s: 0.4s linear;
@linear5s: 0.5s linear;


*, *:focus, *:visited { outline: none; }
* { box-sizing: border-box; text-shadow: none; }

.clear,.clearfix { clear: both; }
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary { display: block; }
audio, canvas, video { display: inline; zoom: 1; }
audio:not([controls]) { display: none; height: 0; }
html { font-size: 100%; text-size-adjust: 100%; -ms-text-size-adjust: 100%; height: 100% !important; min-height: 100% !important; }
/*html,html a { font-smoothing: antialiased; }*/
a:hover,
a:focus,
a:visited { text-decoration: none; }
a { text-decoration: none; outline: none; }
html,button,input,select,textarea { font-weight: 300; font-family: @regular !important; margin: 0; padding: 0; }

body {
	background: @white;
	font-weight: 300;
	font-family: @regular;
	font-size: 18px;
	color: @black;
	width: 100%;
	height: 100%;
	margin: 0 !important;
	padding: 0 !important;
	&.off { overflow: hidden !important; }
}
::selection {
	background: @black;
	color: @white;
}
::-moz-selection {
	background: @black;
	color: @white;
}


header {
	position: fixed;
	top: 0;
	left: 0;
	background: @white;
	width: 100%;
	height: 150px;
	z-index: 9999;
	transition: @curve400;
	&.flex-align {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&.block-align { display: block; }
	&.open-nav {
		height: 100vh;
		overflow: hidden;
	}
	h1 {
		float: left;
		display: block;
		background: url('../images/logo.png') no-repeat 50% 50%;
		width: 213px;
		height: 79px;
		margin: 0 0 0 40px;
		a {
			display: block;
			width: 100%;
			height: 100%;
			text-indent: -9999em;
		}
		@media (max-width: 767px) {
			transform: scale(0.7) translateX(-30%) translateY(5px);
		}
	}
	button.nav-btn {
		position: absolute;
		top: 25px;
		right: 5%;
		display: none;
		background: transparent;
		width: 50px;
		height: 50px;
		border: 0;
		border-radius: 0;
		.line {
			display: block;
			background: @grey;
			width: 40px;
			height: 4px;
			margin: 0 auto;
			transition: @curve300;
			transform-origin: center center;
			& + .line { margin-top: 5px; }
		}
		&.active {
			.line {
				margin-top: 0;
				&:nth-of-type(1) {
					transform: rotate(45deg) translateY(5px);
				}
				&:nth-of-type(2) {
					transform: scaleX(0);
				}
				&:nth-of-type(3) {
					transform: rotate(-45deg) translateY(-5px);
				}
			}
		}
	}
	nav {
		float: right;
		display: block;
		width: auto;
		height: auto;
		text-align: right;
		transition: @curve400;
		margin-right: 30px;
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			.nav-item {
				display: inline-block;
				width: auto;
				position: relative;
				margin: 0 15px;
				.nav-link {
					display: block;
					width: auto;
					height: auto;
					font-family: @bold;
					color: @grey;
					text-transform: uppercase;
					position: relative;
					&:after {
						position: absolute;
						bottom: -6px;
						left: 0;
						display: block;
						content: '';
						background: @black;
						width: 0;
						height: 2px;
						transition: @curve400;
					}
					&:before {
						position: absolute;
						top: 0;
						max-width: 0;
						content: attr(data-link);
						color: @pink;
						white-space: nowrap;
						overflow: hidden;
						transition: @curve400;
					}
					&:hover,
					&.current {
						color: @grey;
						&:after { width: 100%; }
						&:before { max-width: 100%; }
					}
				}
			}
		}
	}
	@media (max-width: 767px){
		height: 90px;
		button.nav-btn { display: block; }
		nav {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 0;
			overflow: hidden;
			transform: translateY(-50%);
			ul {
				.nav-item {
					display: block;
					width: 50%;
					height: 60px;
					line-height: 60px;
					text-align: center;
					margin: 0 auto 20px;
					opacity: 0;
					transform: translateX(-30px);
					.nav-link {
						font-size: 2em;
					}
				}
			}
		}
	}
}

.itsawrap {
	float: left;
	display: block;
	width: 100%;
	height: auto;
	section {
		float: left;
		display: block;
		width: 100%;
		position: relative;
		z-index: 2;
		&.bcg-parallax { position: relative; }
		&#home {
			background: @black;
			height: 80vh;
			overflow: hidden;
			&:after {
				position: absolute;
				bottom: 0;
				left: 0;
				display: block;
				content: '';
				width: 0;
				height: 0;
				border-bottom: 45px solid @white;
				border-left: 50vw solid transparent;
				border-right: 50vw solid transparent;
				z-index: 99;
			}
			.bcg {
				position: absolute;
				top: 0;
				background: url('../images/hero-img.jpg') no-repeat 50% 50%;
				background-size: cover;
				width: 100%;
				height: 100vh;
				z-index: 1;
			}
			.content-wrapper {
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				z-index: 2;
				color: @white;
				transform: translateY(-50%);
				text-align: center;
				z-index: 99;
				strong {
					display: block;
					font-family: @bold;
					font-size: 4em;
					text-shadow: 2px 2px 6px @black;
				}
				span {
					display: block;
					font-family: @light;
					font-size: 4em;
					text-shadow: 2px 2px 6px @black;
					margin-top: -30px;
				}
				@media (max-width: 767px) {
					strong { font-size: 3em; }
					span { font-size: 2em; margin-top: -10px;}
				}
			}
		}
		&#chisono {
			position: relative;
			@keyframes moveArrow {
				0% { transform: translate(-50%,0); }
				60% { transform: translate(-50%,30px); }
				100% { transform: translate(-50%,0); }
			}
			&:before {
				position: absolute;
				top: -30px;
				left: 50%;
				display: block;
				content: '\2193';
				font-size: 2em;
				font-family: @light;
				color: @grey;
				transform: translate(-50%,0);
				animation: moveArrow @curve1400 infinite;
			}
			background: @white;
			padding: 100px 0 150px;
			figure.photo {
				display: block;
				width: 200px;
				height: 200px;
				border-radius: 50%;
				margin: 0 auto 50px;
				overflow: hidden;
				img { width: 100%; }
			}
			
			.dati {
				float: right;
				display: block;
				width: 100%;
				font-family: @regular;
				text-align: right;
				text-transform: uppercase;
				.nome {
					display: block;
					font-family: @bold;
					color: @grey;
					font-size: 2em;
					line-height: 0.85em;
					margin-bottom: 20px;
				}
				.anni { display: inline-block; }
				@media (max-width: 767px) {
					text-align: center;
					margin: 20px 0;
				}
			}
			.text {
				font-family: @regular;
				font-size: 1.2em;
				strong { font-family: @bold; }
				@media (max-width: 767px) {
					text-align: center;
				}
			}
		}
		&#servizi {
			padding-bottom: 100px;
			.parallax-zone {
				float: left;
				display: block;
				background: @black;
				width: 100%;
				height: 400px;
				position: relative;
				overflow: hidden;
				margin-bottom: 50px;
				&:after {
					position: absolute;
					top: 0;
					left: -10%;
					display: block;
					content: '';
					width: 120%;
					height: 400px;
					box-shadow: inset 4px 4px 18px rgba(0,0,0,0.5);
					z-index: 2;
				}
				.bcg-servizi {
					position: absolute;
					background: url('../images/parallax.jpg') no-repeat 50% 50%;
					background-size: cover;
					width: 100%;
					height: 200%;
					top: 0;
					z-index: 1;
					opacity: 0.8;
				}
			}
			.box {
				margin: 15px 0;
				.servizio {
					float: left;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					min-height: 188px;
					font-family: @bold;
					font-size: 1.2em;
					color: @white;
					text-align: center;
					padding: 10px;
					box-sizing: border-box;
				}
				&:nth-of-type(1) .servizio { background: @pink; }
				&:nth-of-type(2) .servizio { background: @water; }
				&:nth-of-type(3) .servizio { background: @violet; }
				&:nth-of-type(4) .servizio { background: @brown; }
				&:nth-of-type(5) .servizio { background: @blue; }
				&:nth-of-type(6) .servizio { background: @lightblue; }
			}
		}
	}
}

footer#foot {
	float: left;
	display: block;
	background: @grey;
	width: 100%;
	height: auto;
	color: @white;
	padding: 60px 0 100px;
	.content-box {
		&.flex-align {
			display: flex !important;
			align-items: center !important;
		}
		&.block-align { display: block; }
	}
	.contatti {
		float: left;
		display: block;
		width: 100%;
		height: auto;
		font-family: @light;
		font-size: 1.2em;
		strong {
			font-family: @bold;
		}
		a { color: @white; }
	}
	.form {
		float: right; 
		display: block;
		width: 300px;
		height: auto;
		position: relative;
		.message {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			background: @grey;
			width: 100%;
			height: 100%;
			opacity: 0;
			visibility: hidden;
			transition: @linear2s;
			z-index: 2;
			span {
				position: absolute;
				top: 50%;
				left: 0;
				display: block;
				width: 100%;
				height: auto;
				font-size: 1.3em;
				text-align: center;
				transform: translateY(-50%);
			}
			&.showmessage {
				opacity: 1;
				visibility: visible;
			}
		}
		form {
			float: right;
			display: block;
			width: 100%;
			height: auto;
			position: relative;
			z-index: 0;
			.group {
				float: left;
				display: block;
				width: 100%;
				height: auto;
				position: relative;
				margin-bottom: 10px;
				overflow: hidden;
				input, textarea {
					float: left;
					display: block;
					background: @white;
					width: 300px;
					height: 50px;
					color: @black;
					line-height: 50px;
					border-radius: 6px;
					border: 0;
					position: relative;
					z-index: 1;
					padding-left: 3%;
					&[type="checkbox"] {
						display: inline-block;
						width: 20px;
						height: 20px;
						margin: 10px 10px 10px 0;
						transform: translateX(0);
						&:focus ~ label, &:valid ~ label {
							transform: translateX(0);
							transition: @curve400;
						}
					}
					&:focus ~ label, &:valid ~ label {
						transform: translateX(-200%);
						transition: @curve400;
					}
				}
				textarea {
					height: 120px;
					resize: none;
				}
				label {
					position: absolute;
					top: 13px;
					left: 3%;
					float: left;
					display: block;
					color: @grey;
					width: auto;
					height: auto;
					z-index: 2;
					padding: 0;
					margin: 0;
					transform: translateX(0);
				}
				a.invia {
					float: left;
					display: block;
					background: @pink;
					width: 300px;
					height: 50px;
					line-height: 50px;
					border-radius: 6px;
					border: 0;
					cursor: pointer;
					position: relative;
					overflow: hidden;
					&.disabled {
						opacity: 0.4;
						pointer-events: none;
					}
					span {
						position: absolute;
						top: 50%;
						left: 50%;
						display: block;
						width: 100%;
						height: auto;
						font-family: @bold;
						color: @grey;
						font-size: 1.5em;
						text-transform: uppercase;
						text-align: center;
						transform: translate(-50%,-50%);
						z-index: 3;
						&:before {
							position: absolute;
							top: 0;
							max-width: 0;
							content: attr(data-text);
							color: @pink;
							white-space: nowrap;
							overflow: hidden;
							transition: @curve400;
						}
					}
					&:after {
						position: absolute;
						top: 0;
						left: 0;
						width: 0;
						height: 100%;
						background: @black;
						content: '';
						overflow: hidden;
						transition: @curve400;
						z-index: 2
					}
					&:hover {
						&:after { width: 100%; }
						span:before { max-width: 100%; }
					}
				}
				&.privacy {
					height: 100px;
					label {
						left: 30px;
						color: @white;
						top: 5px;
						font-family: @light;
						font-size: .7em;
						a { color: @white; }
					}
				}
			}
		}
	}
	@media (max-width: 767px) {
		.contatti, { text-align: center; margin-bottom: 50px; }
		.form {
			float: none;
			margin: 0 auto;
		}
	}
}