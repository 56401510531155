@font-face {
	font-family: 'DIN-Light';
	src: url('../fonts/DIN-Light.eot');
	src: url('../fonts/DIN-Light.woff') format('woff'),
		 url('../fonts/DIN-Light.ttf') format('truetype'),
		 url('../fonts/DIN-Light.eot?#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DIN-Regular';
	src: url('../fonts/DIN-Regular.eot');
	src: url('../fonts/DIN-Regular.woff') format('woff'),
		 url('../fonts/DIN-Regular.ttf') format('truetype'),
		 url('../fonts/DIN-Regular.eot?#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DIN-Medium';
	src: url('../fonts/DIN-Medium.eot');
	src: url('../fonts/DIN-Medium.woff') format('woff'),
		 url('../fonts/DIN-Medium.ttf') format('truetype'),
		 url('../fonts/DIN-Medium.eot?#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DIN-Bold';
	src: url('../fonts/DIN-Bold.eot');
	src: url('../fonts/DIN-Bold.woff') format('woff'),
		 url('../fonts/DIN-Bold.ttf') format('truetype'),
		 url('../fonts/DIN-Bold.eot?#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DIN-Black';
	src: url('../fonts/DIN-Black.eot');
	src: url('../fonts/DIN-Black.woff') format('woff'),
		 url('../fonts/DIN-Black.ttf') format('truetype'),
		 url('../fonts/DIN-Black.eot?#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
}
