@font-face {
  font-family: 'DIN-Light';
  src: url('../fonts/DIN-Light.eot');
  src: url('../fonts/DIN-Light.woff') format('woff'), url('../fonts/DIN-Light.ttf') format('truetype'), url('../fonts/DIN-Light.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DIN-Regular';
  src: url('../fonts/DIN-Regular.eot');
  src: url('../fonts/DIN-Regular.woff') format('woff'), url('../fonts/DIN-Regular.ttf') format('truetype'), url('../fonts/DIN-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DIN-Medium';
  src: url('../fonts/DIN-Medium.eot');
  src: url('../fonts/DIN-Medium.woff') format('woff'), url('../fonts/DIN-Medium.ttf') format('truetype'), url('../fonts/DIN-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DIN-Bold';
  src: url('../fonts/DIN-Bold.eot');
  src: url('../fonts/DIN-Bold.woff') format('woff'), url('../fonts/DIN-Bold.ttf') format('truetype'), url('../fonts/DIN-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DIN-Black';
  src: url('../fonts/DIN-Black.eot');
  src: url('../fonts/DIN-Black.woff') format('woff'), url('../fonts/DIN-Black.ttf') format('truetype'), url('../fonts/DIN-Black.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
*,
*:focus,
*:visited {
  outline: none;
}
* {
  box-sizing: border-box;
  text-shadow: none;
}
.clear,
.clearfix {
  clear: both;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline;
  zoom: 1;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
html {
  font-size: 100%;
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100% !important;
  min-height: 100% !important;
}
/*html,html a { font-smoothing: antialiased; }*/
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}
a {
  text-decoration: none;
  outline: none;
}
html,
button,
input,
select,
textarea {
  font-weight: 300;
  font-family: 'DIN-Regular' !important;
  margin: 0;
  padding: 0;
}
body {
  background: #fff;
  font-weight: 300;
  font-family: 'DIN-Regular';
  font-size: 18px;
  color: #121212;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
body.off {
  overflow: hidden !important;
}
::selection {
  background: #121212;
  color: #fff;
}
::-moz-selection {
  background: #121212;
  color: #fff;
}
header {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 150px;
  z-index: 9999;
  transition: 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}
header.flex-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header.block-align {
  display: block;
}
header.open-nav {
  height: 100vh;
  overflow: hidden;
}
header h1 {
  float: left;
  display: block;
  background: url('../images/logo.png') no-repeat 50% 50%;
  width: 213px;
  height: 79px;
  margin: 0 0 0 40px;
}
header h1 a {
  display: block;
  width: 100%;
  height: 100%;
  text-indent: -9999em;
}
@media (max-width: 767px) {
  header h1 {
    transform: scale(0.7) translateX(-30%) translateY(5px);
  }
}
header button.nav-btn {
  position: absolute;
  top: 25px;
  right: 5%;
  display: none;
  background: transparent;
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 0;
}
header button.nav-btn .line {
  display: block;
  background: #666;
  width: 40px;
  height: 4px;
  margin: 0 auto;
  transition: 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  transform-origin: center center;
}
header button.nav-btn .line + .line {
  margin-top: 5px;
}
header button.nav-btn.active .line {
  margin-top: 0;
}
header button.nav-btn.active .line:nth-of-type(1) {
  transform: rotate(45deg) translateY(5px);
}
header button.nav-btn.active .line:nth-of-type(2) {
  transform: scaleX(0);
}
header button.nav-btn.active .line:nth-of-type(3) {
  transform: rotate(-45deg) translateY(-5px);
}
header nav {
  float: right;
  display: block;
  width: auto;
  height: auto;
  text-align: right;
  transition: 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  margin-right: 30px;
}
header nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
header nav ul .nav-item {
  display: inline-block;
  width: auto;
  position: relative;
  margin: 0 15px;
}
header nav ul .nav-item .nav-link {
  display: block;
  width: auto;
  height: auto;
  font-family: 'DIN-Bold';
  color: #666;
  text-transform: uppercase;
  position: relative;
}
header nav ul .nav-item .nav-link:after {
  position: absolute;
  bottom: -6px;
  left: 0;
  display: block;
  content: '';
  background: #121212;
  width: 0;
  height: 2px;
  transition: 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}
header nav ul .nav-item .nav-link:before {
  position: absolute;
  top: 0;
  max-width: 0;
  content: attr(data-link);
  color: #ef91aa;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}
header nav ul .nav-item .nav-link:hover,
header nav ul .nav-item .nav-link.current {
  color: #666;
}
header nav ul .nav-item .nav-link:hover:after,
header nav ul .nav-item .nav-link.current:after {
  width: 100%;
}
header nav ul .nav-item .nav-link:hover:before,
header nav ul .nav-item .nav-link.current:before {
  max-width: 100%;
}
@media (max-width: 767px) {
  header {
    height: 90px;
  }
  header button.nav-btn {
    display: block;
  }
  header nav {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    transform: translateY(-50%);
  }
  header nav ul .nav-item {
    display: block;
    width: 50%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    margin: 0 auto 20px;
    opacity: 0;
    transform: translateX(-30px);
  }
  header nav ul .nav-item .nav-link {
    font-size: 2em;
  }
}
.itsawrap {
  float: left;
  display: block;
  width: 100%;
  height: auto;
}
.itsawrap section {
  float: left;
  display: block;
  width: 100%;
  position: relative;
  z-index: 2;
}
.itsawrap section.bcg-parallax {
  position: relative;
}
.itsawrap section#home {
  background: #121212;
  height: 80vh;
  overflow: hidden;
}
.itsawrap section#home:after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-bottom: 45px solid #fff;
  border-left: 50vw solid transparent;
  border-right: 50vw solid transparent;
  z-index: 99;
}
.itsawrap section#home .bcg {
  position: absolute;
  top: 0;
  background: url('../images/hero-img.jpg') no-repeat 50% 50%;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: 1;
}
.itsawrap section#home .content-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 2;
  color: #fff;
  transform: translateY(-50%);
  text-align: center;
  z-index: 99;
}
.itsawrap section#home .content-wrapper strong {
  display: block;
  font-family: 'DIN-Bold';
  font-size: 4em;
  text-shadow: 2px 2px 6px #121212;
}
.itsawrap section#home .content-wrapper span {
  display: block;
  font-family: 'DIN-Light';
  font-size: 4em;
  text-shadow: 2px 2px 6px #121212;
  margin-top: -30px;
}
@media (max-width: 767px) {
  .itsawrap section#home .content-wrapper strong {
    font-size: 3em;
  }
  .itsawrap section#home .content-wrapper span {
    font-size: 2em;
    margin-top: -10px;
  }
}
.itsawrap section#chisono {
  position: relative;
  background: #fff;
  padding: 100px 0 150px;
}
@keyframes moveArrow {
  0% {
    transform: translate(-50%, 0);
  }
  60% {
    transform: translate(-50%, 30px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
.itsawrap section#chisono:before {
  position: absolute;
  top: -30px;
  left: 50%;
  display: block;
  content: '\2193';
  font-size: 2em;
  font-family: 'DIN-Light';
  color: #666;
  transform: translate(-50%, 0);
  animation: moveArrow 1.4s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
.itsawrap section#chisono figure.photo {
  display: block;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto 50px;
  overflow: hidden;
}
.itsawrap section#chisono figure.photo img {
  width: 100%;
}
.itsawrap section#chisono .dati {
  float: right;
  display: block;
  width: 100%;
  font-family: 'DIN-Regular';
  text-align: right;
  text-transform: uppercase;
}
.itsawrap section#chisono .dati .nome {
  display: block;
  font-family: 'DIN-Bold';
  color: #666;
  font-size: 2em;
  line-height: 0.85em;
  margin-bottom: 20px;
}
.itsawrap section#chisono .dati .anni {
  display: inline-block;
}
@media (max-width: 767px) {
  .itsawrap section#chisono .dati {
    text-align: center;
    margin: 20px 0;
  }
}
.itsawrap section#chisono .text {
  font-family: 'DIN-Regular';
  font-size: 1.2em;
}
.itsawrap section#chisono .text strong {
  font-family: 'DIN-Bold';
}
@media (max-width: 767px) {
  .itsawrap section#chisono .text {
    text-align: center;
  }
}
.itsawrap section#servizi {
  padding-bottom: 100px;
}
.itsawrap section#servizi .parallax-zone {
  float: left;
  display: block;
  background: #121212;
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
}
.itsawrap section#servizi .parallax-zone:after {
  position: absolute;
  top: 0;
  left: -10%;
  display: block;
  content: '';
  width: 120%;
  height: 400px;
  box-shadow: inset 4px 4px 18px rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.itsawrap section#servizi .parallax-zone .bcg-servizi {
  position: absolute;
  background: url('../images/parallax.jpg') no-repeat 50% 50%;
  background-size: cover;
  width: 100%;
  height: 200%;
  top: 0;
  z-index: 1;
  opacity: 0.8;
}
.itsawrap section#servizi .box {
  margin: 15px 0;
}
.itsawrap section#servizi .box .servizio {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 188px;
  font-family: 'DIN-Bold';
  font-size: 1.2em;
  color: #fff;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}
.itsawrap section#servizi .box:nth-of-type(1) .servizio {
  background: #ef91aa;
}
.itsawrap section#servizi .box:nth-of-type(2) .servizio {
  background: #60dac6;
}
.itsawrap section#servizi .box:nth-of-type(3) .servizio {
  background: #b791dc;
}
.itsawrap section#servizi .box:nth-of-type(4) .servizio {
  background: #908169;
}
.itsawrap section#servizi .box:nth-of-type(5) .servizio {
  background: #84aadd;
}
.itsawrap section#servizi .box:nth-of-type(6) .servizio {
  background: #6dcdda;
}
footer#foot {
  float: left;
  display: block;
  background: #666;
  width: 100%;
  height: auto;
  color: #fff;
  padding: 60px 0 100px;
}
footer#foot .content-box.flex-align {
  display: flex !important;
  align-items: center !important;
}
footer#foot .content-box.block-align {
  display: block;
}
footer#foot .contatti {
  float: left;
  display: block;
  width: 100%;
  height: auto;
  font-family: 'DIN-Light';
  font-size: 1.2em;
}
footer#foot .contatti strong {
  font-family: 'DIN-Bold';
}
footer#foot .contatti a {
  color: #fff;
}
footer#foot .form {
  float: right;
  display: block;
  width: 300px;
  height: auto;
  position: relative;
}
footer#foot .form .message {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: #666;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s linear;
  z-index: 2;
}
footer#foot .form .message span {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  height: auto;
  font-size: 1.3em;
  text-align: center;
  transform: translateY(-50%);
}
footer#foot .form .message.showmessage {
  opacity: 1;
  visibility: visible;
}
footer#foot .form form {
  float: right;
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 0;
}
footer#foot .form form .group {
  float: left;
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}
footer#foot .form form .group input,
footer#foot .form form .group textarea {
  float: left;
  display: block;
  background: #fff;
  width: 300px;
  height: 50px;
  color: #121212;
  line-height: 50px;
  border-radius: 6px;
  border: 0;
  position: relative;
  z-index: 1;
  padding-left: 3%;
}
footer#foot .form form .group input[type="checkbox"],
footer#foot .form form .group textarea[type="checkbox"] {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 10px 10px 10px 0;
  transform: translateX(0);
}
footer#foot .form form .group input[type="checkbox"]:focus ~ label,
footer#foot .form form .group textarea[type="checkbox"]:focus ~ label,
footer#foot .form form .group input[type="checkbox"]:valid ~ label,
footer#foot .form form .group textarea[type="checkbox"]:valid ~ label {
  transform: translateX(0);
  transition: 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}
footer#foot .form form .group input:focus ~ label,
footer#foot .form form .group textarea:focus ~ label,
footer#foot .form form .group input:valid ~ label,
footer#foot .form form .group textarea:valid ~ label {
  transform: translateX(-200%);
  transition: 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}
footer#foot .form form .group textarea {
  height: 120px;
  resize: none;
}
footer#foot .form form .group label {
  position: absolute;
  top: 13px;
  left: 3%;
  float: left;
  display: block;
  color: #666;
  width: auto;
  height: auto;
  z-index: 2;
  padding: 0;
  margin: 0;
  transform: translateX(0);
}
footer#foot .form form .group a.invia {
  float: left;
  display: block;
  background: #ef91aa;
  width: 300px;
  height: 50px;
  line-height: 50px;
  border-radius: 6px;
  border: 0;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
footer#foot .form form .group a.invia.disabled {
  opacity: 0.4;
  pointer-events: none;
}
footer#foot .form form .group a.invia span {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: auto;
  font-family: 'DIN-Bold';
  color: #666;
  font-size: 1.5em;
  text-transform: uppercase;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 3;
}
footer#foot .form form .group a.invia span:before {
  position: absolute;
  top: 0;
  max-width: 0;
  content: attr(data-text);
  color: #ef91aa;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}
footer#foot .form form .group a.invia:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #121212;
  content: '';
  overflow: hidden;
  transition: 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: 2;
}
footer#foot .form form .group a.invia:hover:after {
  width: 100%;
}
footer#foot .form form .group a.invia:hover span:before {
  max-width: 100%;
}
footer#foot .form form .group.privacy {
  height: 100px;
}
footer#foot .form form .group.privacy label {
  left: 30px;
  color: #fff;
  top: 5px;
  font-family: 'DIN-Light';
  font-size: .7em;
}
footer#foot .form form .group.privacy label a {
  color: #fff;
}
@media (max-width: 767px) {
  footer#foot .contatti {
    text-align: center;
    margin-bottom: 50px;
  }
  footer#foot .form {
    float: none;
    margin: 0 auto;
  }
}
